<script>
import Input from '@/components/Input.vue'
import Head from './../../components/Head.vue'
import SectionTitle from './../../components/SectionTitle.vue'
import Button from '@/components/Button.vue'
import Section from '@/components/Section.vue'
import Table from './../../components/Table.vue'
import Select from '@/components/Select.vue'
import ReturnToPage from '@/components/ReturnToPage.vue'
import Loader from './../../components/Loader.vue'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import DeleteModal from '@/components/DeleteModal.vue'

export default {
    props: [ 'userData' ],
    data() {
        return {
            loader: true,
            view: 'list-cbos',
            hasNextPage: undefined,
            itemsPerPage: 10,
            actualPage: 0,
            totalItems: 0,
            totalPages: 0,
            searchTermsFilter: '',
            tableHeaders: [
                'ID',
                'Nome',
                'Curso',
                'Data',
                'Presença',
            ],
            tableActions: [
                'trash'
            ],
            tableContents: [],
            tableContentsFullInfos: [],
            tableContentsFullInfosSelectedUser: [],
            editPresence: {
                user_id: 0,
                course: 'administracao',
                date: '',
                presence: false,
            },
            statusCompaniesSelect: [],
            youngApprenticeSelect: [],
            selectedDeleteId: 0,
            deleteApprenticeModal: false,
            presenceSelect: [
                {
                    name: 'Falta',
                    value: 0,
                    selected: true
                },
                {
                    name: 'Presença',
                    value: 1,
                    selected: false
                },
            ], 
            coursesSelect: [
                {
                    name: 'Administração',
                    value: 'administracao',
                    selected: true
                },
                {
                    name: 'Atendente de Lanchonete',
                    value: 'atendente-de-lanchonete',
                    selected: false
                },
                {
                    name: 'Auxiliar de Gastronomia',
                    value: 'auxiliar-de-gastronomia',
                    selected: false
                },
                {
                    name: 'Camareira de Hotel',
                    value: 'camareira-de-hotel',
                    selected: false
                },
                {
                    name: 'Copeira Hospitalar',
                    value: 'copeira-hospitalar',
                    selected: false
                },
                {
                    name: 'Recepcionista',
                    value: 'recepcionista',
                    selected: false
                },
                {
                    name: 'Repositor / Demonstrador de Mercadorias',
                    value: 'repositor-demonstrador-de-mercadorias',
                    selected: false
                },
                {
                    name: 'Telemarketing Ativo e Receptivo',
                    value: 'telemarketing-ativo-e-receptivo',
                    selected: false
                },
            ], 
            searchPresence: false,
        }
    },
    created() {
        /* this.getNextPageApprentices(); */
        this.loader = true;
            
        this.$axios
        .get("contracts/data/to_contract")
        .then((res) => {
            const data = res.data.data;
            console.log(data);

            this.editPresence.user_id = data.young_apprentices[0].id;

            data.young_apprentices.forEach((element) => {
                this.youngApprenticeSelect.push({
                    name: element.name,
                    value: element.id,
                });
            });

            this.loader = false;
        })
        .catch((err) => {
            this.notify(
                "Ocorreu um erro ao tentar abrir a tela de cadastro de contratos!",
                "error"
            );
            this.loader = false;
        });
    },
    methods: {
        notify(text, type) {
            toast(text, {
                "type": type == 'info' ? 'info' : type == 'warning' ? 'warning' : type == 'error' ? 'error' : type == 'success' ? 'success' : 'default',
            });
        },
        getApprentices() {
            this.loader = true;

            this.actualPage = 1;

            this.$axios.get(`/presences/${this.editPresence.user_id}?items_per_page=${this.itemsPerPage}&page=${this.actualPage}&terms_filter=${this.searchTermsFilter}`)
            .then(res => {
                console.log(res);

                const data = res.data.data.data;

                this.tableContentsFullInfos = data;

                var arr = [];

                data.forEach(element => {
                    arr.push({
                        id: element.id,
                        name: element.user.name,
                        course: element.course.replace(/[^a-zA-Z0-9]+/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(''),
                        date: this.formatDate(element.date),
                        presence: element.presence ? 'Presença' : 'Falta'
                    });
                });

                this.tableContents = arr;

                res.data.data.last_page > 1 ? this.hasNextPage = true : this.hasNextPage = false;

                this.totalItems = res.data.data.total;
                this.totalPages = res.data.data.last_page;

                this.searchPresence = true;
                this.loader = false;
            })
            .catch(err => {
                console.log(err);
            });
        },
        getNextPageApprentices() {
            this.loader = true;

            this.actualPage += 1;

            this.$axios.get(`/presences/${this.editPresence.user_id}?items_per_page=${this.itemsPerPage}&page=${this.actualPage}&terms_filter=${this.searchTermsFilter}`)
            .then(res => {
                console.log(res);

                const data = res.data.data.data;

                this.tableContentsFullInfos = data;

                var arr = [];

                data.forEach(element => {
                    arr.push({
                        id: element.id,
                        name: element.user.name,
                        course: element.course.replace(/[^a-zA-Z0-9]+/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(''),
                        date: this.formatDate(element.date),
                        presence: element.presence ? 'Presença' : 'Falta'
                    });
                });

                this.tableContents = arr;

                res.data.data.last_page > 1 ? this.hasNextPage = true : this.hasNextPage = false;

                this.totalItems = res.data.data.total;
                this.totalPages = res.data.data.last_page;

                this.loader = false;
            })
            .catch(err => {
                console.log(err);
            });
        },
        listApprenticesActions(event) {
            if (event.eventType == 'trash') {
                for (let i = 0; i < this.tableContentsFullInfos.length; i++) {
                    if (this.tableContentsFullInfos[i].id === event.data.id) {
                        this.selectedDeleteId = this.tableContentsFullInfos[i].id;
                    }
                }

                this.deleteApprenticeModal = true;
            }
        },
        modalDeleteClickedButton(event) {
            console.log(event);
            if (!event) {
                this.selectedDeleteId = null;
                this.deleteApprenticeModal = false;
            }

            if (event) {
                this.loader = true;

                this.$axios.delete(`/presences/${this.selectedDeleteId}`)
                .then(res => {
                    this.notify('Vaga removida com sucesso!', 'success');

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                })
                .catch(err => {
                    this.notify('Ocorreu um erro e não foi possível remover essa vaga!', 'error');
                });
            }
        },
        actionPaginationArrows(event) {
            console.log(event);
            if (event == 'previous') {
                this.actualPage -= 2;

                this.getNextPageApprentices();
            };

            if (event == 'next') {
                this.getNextPageApprentices();
            };
        },
        actionPaginationPages(event) {
            this.actualPage = event - 1;

            this.getNextPageApprentices();
        },
        getApprenticesFilter() {
            this.actualPage = 0;

            this.getNextPageApprentices();
        },
        editYoungApprenticies() {
            if (this.view == 'add-cbos') {
                console.log(this.editPresence);
                if (!this.editPresence.course) {
                    return this.notify('Selecione um curso', 'error');
                }

                if (!this.editPresence.date) {
                    return this.notify('Selecione a data de presença', 'error');
                }

                this.loader = true;

                this.$axios.post('presences', this.editPresence)
                .then(res => {
                    this.notify('Presença criada com sucesso!', 'success');

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)
                })
                .catch(err => {
                    this.notify('Ocorreu um erro durante a criação da presença!', 'error');
                    this.loader = false;
                });
            }
        },
        formatDate(dateString) {
            const [year, month, day] = dateString.split('-'); 
            return `${day}/${month}/${year}`;
        },
        addPresence() {
            this.view = "add-cbos";
        },
    },
    components: { Head, SectionTitle, Input, Button, Section, Table, Select, ReturnToPage, Loader, DeleteModal }
}
</script>

<template>
    <Loader v-if="loader" />

    <DeleteModal v-if="deleteApprenticeModal" @clickButton="modalDeleteClickedButton" title="Deletar aprendiz" />

    <section>
        <Head :title="view == 'list-cbos' ? 'Presenças' : view == 'edit-cbos' ? 'Editar Presença' : 'Adicionar Presença'" />

        <!-- Adicionar ou editar Vaga -->
        <Section v-if="view == 'edit-cbos' || view == 'add-cbos'">
            <ReturnToPage placeholder="Voltar para a listagem de vagas" redirectTo="" @clickReturn="view = 'list-cbos'"></ReturnToPage>

            <div class="form-divider"></div>

            <Select
                label="Selecione o curso"
                :options="coursesSelect"
                :value="editPresence.course"
                v-model="editPresence.course"
            />

            <div class="form-divider"></div>

            <Input type="date" placeholder="dd/mm/yyy" label="Data da presença" :value="editPresence.date" v-model="editPresence.date" />

            <div class="form-divider"></div>

            <Select
                label="Presença"
                :options="presenceSelect"
                :value="editPresence.presence"
                v-model="editPresence.presence"
            />

            <div class="form-divider"></div>

            <Button type="primary" :placeholder="view == 'edit-cbos' ? `Editar Presença` : 'Adicionar Presença'" @buttonPressed="editYoungApprenticies" />
        </Section>

        <!-- Listar Vagas -->
        <Section v-if="view == 'list-cbos'">
            <SectionTitle title="Gerenciar Presenças" />

            <div class="form-divider"></div>

            <section v-if="searchPresence" class="top-add-button">
                <Button @buttonPressed="addPresence" class="add-button" type="primary" placeholder="+ Adicionar Presença" />
            </section>

            <div class="form-divider"></div>

            <Select
                label="Selecione o jovem aprendiz"
                :options="youngApprenticeSelect"
                :value="editPresence.user_id"
                v-model="editPresence.user_id"
            />

            <div class="form-divider"></div>

            <Button @buttonPressed="getApprentices" class="add-button" type="primary" placeholder="Buscar Presenças" />

            <div class="form-divider"></div>

            <Table v-if="!loader && searchPresence" :hasActions="true" :actions="tableActions" :hasNextPage="hasNextPage" :totalItems="totalItems" :totalPages="totalPages" :actualPage="actualPage" :headers="tableHeaders" :contents="tableContents" @loadMore="getNextPage" @clickAction="listApprenticesActions" @paginationArrows="actionPaginationArrows" @paginationPages="actionPaginationPages" />
        </Section>
    </section>
</template>

<style scoped>
.top-add-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: -55px;
}

.add-button {
    width: 300px;
    max-width: 100%;
}

@media screen and (max-width: 700px) {
    .top-add-button {
        margin-top: 0px;
    }

    .add-button {
        width: 100%;
        justify-content: center;
    }
}
</style>